import React from "react";
import ReactGA from "react-ga";

const AdaptiveQuestionnaire = () => {
  ReactGA.event({ category: "Navigate", action: "Surveys" });

  return (
    <article className="flex flex-col xs:flex-row min-h-screen text-gray-900">
      <aside className="bg-gray-400 xs:w-1/2 md:w-1/3 lg:w-3/10 p-2 sm:p-4 lg:p-8">
        <div className="sticky px-4 pb-4 " style={{ top: "80px" }}>
          <h1 className="pt-8 text-xl font-bold text-blue-900">
            Adaptive Questionnaire System
          </h1>
          <p className="mt-8 text-sm font-bold whitespace-no-wrap">Client:</p>
          <p className="font-semibold text-blue-900">
            A Top 30 Accounting Firm
          </p>
          <p className="mt-8 text-sm font-bold whitespace-no-wrap">Overview:</p>
          <p className="font-medium leading-relaxed">
            A large, multi-division financial services client needed an
            organized way to review their clients' scope of operations and
            business needs both at the start of an engagement, and to capture
            changes over time.
          </p>
          <p className="mt-4 font-medium leading-relaxed">
            I created an adaptive questionnaire system that allowed each
            division to specify what questions needed to be asked and the
            criteria under which specific questions would be asked.
          </p>
          <p className="mt-4 font-medium leading-relaxed">
            The result allowed for disparate account managers to produce a
            consolidated profile of each client's business and needs, which was
            updated after each engagement to capture any changes in the client's
            operations. Using this profile, the firm could determine what
            services to target for future engagement opportunities.
          </p>
        </div>
      </aside>
      <section className="px-4 sm:px-8 py-8 sm:py-12 lg:py-16 xs:w-1/2 md:w-2/3 lg:w-7/10 font-medium leading-relaxed bg-cover">
        <p className="">
          A financial services firm had identified a need to better capture
          their clients' scope of business so the firm could coordinate their
          service offerings as they learned more about each client, and as each
          client's business evolved.
        </p>

        <p className="mt-6">
          For example, the services needed by a single-state client are more
          limited than those needed by a multi-state employer, or by an
          international client. As a client's business changed, it was important
          that the firm maintain awareness of these changes so that they could
          identify opportunities to provide additional services to the client.
        </p>

        <p className="mt-6">
          Because the firm offered a wide array of services, any single person
          working with the client might not be aware of any conditions or
          changes that could lead to opportunities for a different division.
          Knowledge about the clients was being siloed, with each division
          having it's own view of the client that didn't always allow them to
          see the opportunity for additional services that were outside of their
          expertise.
        </p>

        <p className="mt-6">
          The firm decided that they needed a system that their servicing
          partners could use to record their knowledge about the clients after
          each engagement. The system would ask the partners a series of
          questions which, depending on their answers, could lead to additional
          questions that would expose new information gained by the partner, and
          trigger notifications to the relevant departments for client
          follow-up.
        </p>

        <p className="mt-6">
          I designed and developed an adaptive questionnaire system which
          allowed the company to design questions that could trigger a wide
          variety of actions based on the answers given. An answer could hide
          questions that were no longer applicable, expose new questions that
          were relevant, branch to a different section of the survey, or
          activate additional surveys that delve into specific areas of
          knowledge. In addition, responses could trigger notifications to
          specific people or business units that new opportunities existed and
          should be explored with the client.
        </p>

        <p className="mt-6">
          Because business needs change over time, the system was designed with
          full questionnaire, question, and answer versioning, so that as much
          information as possible could be compared to prior responses without
          invalidating the results.
        </p>

        <p className="mt-6">
          The system was also created to take into account the multi-divisional
          nature of the firm, allowing "checkpoint" questionnaires to be
          answered by different individuals, each of whom has specific areas of
          expertise and the ability to gather and understand details about those
          aspects of their clients' business.
        </p>

        <p className="mt-6">
          The resulting system allows them to use new information gathered
          during every client engagement to quickly identify and pursue new
          opportunities as their clients' operations evolve.
        </p>
      </section>
    </article>
  );
};

export default AdaptiveQuestionnaire;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";

const CollapsingHeader = ({ showSplash }) => {
  const menuHamburger = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="h-8 w-8 fill-current"
    >
      <path
        fillRule="evenodd"
        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
      />
    </svg>
  );

  const menuCancel = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="h-8 w-8 fill-current"
    >
      <path
        fillRule="evenodd"
        d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
      />
    </svg>
  );

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const menuClick = e => {
    setMenuIsOpen(menuState => !menuState);
  };

  const closeMenu = () => {
    setMenuIsOpen(false);
  };

  return (
    <React.Fragment>
      <header
        className={`sticky top-0 text-blue-100 z-50 transition-bg transition-ease-out ${
          showSplash ? "bg-blue-800" : "bg-blue-900"
        }`}
      >
        <div className="wrapper block sm:flex justify-between px-2 py-2 sm:px-4 sm:py-4 md:px-8">
          <div className="flex justify-between">
            <div className="details flex items-center w-full">
              <Link to="/">
                <div
                  className={`rounded-full overflow-hidden border-gray-500 shrink-none transition-all transition-ease-out ${
                    showSplash
                      ? " h-16 w-16 border-2 xxs:h-18 xxs:w-18 xs:border-3 xs:h-20 xs:w-20 sm:h-24 sm:w-24 md:h-32 md:w-32 "
                      : " h-12 w-12 border-2"
                  }`}
                >
                  <img
                    src={require("../../../images/portrait.jpg")}
                    alt="Avatar"
                    className="h-full w-full object-fill"
                  />
                </div>
              </Link>

              <div className="flex flex-col ml-2 lg:ml-3">
                <div
                  className={`my-name font-medium sm:font-semibold sm:text-lg md:text-xl lg:text-2xl whitespace-no-wrap`}
                >
                  Stephen Cochran
                </div>
                <div
                  className={`my-details md:text-center whitespace-no-wrap ${
                    showSplash ? "text-sm sm:text-base md:text-lg" : "text-sm "
                  }`}
                >
                  Full Stack Developer
                </div>
              </div>
            </div>
            <button
              onClick={menuClick}
              className="block text-blue-100 focus:outline-none focus:text-blue-400 hover:text-blue-400 sm:hidden"
              title={`${menuIsOpen ? "Close menu" : "Open menu"}`}
            >
              {menuIsOpen ? menuCancel : menuHamburger}
            </button>
          </div>
          <Navigation
            menuOpen={menuIsOpen}
            headerCollapsed={!showSplash}
            clickAction={closeMenu}
          />
        </div>
      </header>
      <button
        className={`opacity-50 bg-black inset-0 h-full w-full z-40 ${
          menuIsOpen ? "fixed" : "hidden"
        }`}
        onClick={closeMenu}
      ></button>
    </React.Fragment>
  );
};

export default CollapsingHeader;

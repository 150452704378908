import React from "react";

const ExperienceItem = ({ company, title, dates, tech, children }) => {
  return (
    <article className="sm:last:pb-8 sm:odd:bg-blue-100 w-full pb-8 sm:py-4 lg:py-8">
      <div className="relative mx-0 px-2 max-w-4xl flex flex-col xs:px-4 sm:flex-row md:px-8 lg:px-0 lg:mx-auto">
        <div className="h-full sticky -mx-6 px-6 py-2 bg-blue-100 sm:bg-transparent sm:py-0 sm:mx-6 sm:px-0 sm:top-6 top-4 opacity-1 sm:w-2/5">
          <h2 className="text-lg font-semibold sm:text-xl">{company}</h2>
          <div className="flex justify-between sm:block">
            <p className="font-medium">{title}</p>
            <p>{dates}</p>
            <p className="mt-2 font-smaller">{tech}</p>
          </div>
        </div>
        <div className="mt-4 sm:mt-0 sm:w-3/5">{children}</div>
      </div>
    </article>
  );
};

export default ExperienceItem;

import React from "react";
import NavigationLink from "./NavigationLink/NavigationLink";
import PropTypes from "prop-types";

const Navigation = props => {
  const {
    menuOpen,
    headerCollapsed: collapsed,
    clickAction: menuClick
  } = props;

  const menuItems = [
    { url: "/", label: "Home", style: "" },
    {
      url: "/projects",
      label: "Projects",
      style: "sm:ml-3 md:ml-6 lg:ml-8"
    },
    {
      url: "/experience",
      label: "Experience",
      style: "sm:ml-3 md:ml-6 lg:ml-8"
    }
  ];

  return (
    <React.Fragment>
      <nav
        className={`flex-col mt-2 font-medium text-base sm:flex sm:flex-row md:mt-0 sm:items-center sm:font-normal md:font-medium lg:font-semibold lg:text-lg ${
          menuOpen ? "flex" : "hidden"
        }`}
      >
        {menuItems.map((item, index) => {
          return (
            <NavigationLink
              key={index}
              to={item.url}
              label={item.label}
              addClass={item.style}
              clickAction={menuClick}
              collapsed={collapsed}
            />
          );
        })}
      </nav>
    </React.Fragment>
  );
};

Navigation.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  headerCollapsed: PropTypes.bool.isRequired,
  clickAction: PropTypes.func.isRequired
};

export default Navigation;

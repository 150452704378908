import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import ReactGA from "react-ga";

import ScrollToTop from "./ScrollToTop";
import Header from "./Header";
import Footer from "./Footer";
import About from "./Home";
import Experience from "./Experience";
import {
  Impairment,
  Payroll,
  ProjectList,
  OpinionSurveys,
  AdaptiveQuestionnaire
} from "./Projects";

const App = props => {
  const [showSplash, setShowSplash] = useState(true);

  const initializeReactGA = () => {
    ReactGA.initialize("UA-125561727-4");
    ReactGA.pageview("/home");
  };

  const observerAction = entries => {
    let entry = entries[0];

    if (entry.intersectionRatio === 1 && !showSplash) {
      setShowSplash(true);
      document.querySelector("header").classList.add("splash");
    } else if (entry.intersectionRatio !== 1 && showSplash) {
      setShowSplash(false);
      document.querySelector("header").classList.remove("splash");
    }
  };

  const observer = new IntersectionObserver(observerAction, {
    root: document.querySelector("#scrollArea"),
    threshold: 1,
    rootMargin: "0px 0px 0px 0px"
  });

  useEffect(() => {
    observer.observe(document.querySelector("#scroller"));
  }, [observer]);

  initializeReactGA();

  return (
    <div className={props.className}>
      <ScrollToTop />
      <div className="min-h-full flex flex-col">
        <div
          id="scroller"
          className={`h-1 m-0 bg-blue-800 transition-bg transition-ease-out ${
            showSplash ? "bg-blue-800" : "bg-blue-900"
          }`}
        >
          &nbsp;
        </div>
        <Header showSplash={showSplash} />
        <main className="content bg-gray-100 text-gray-900">
          <Route exact path={["/"]} component={About} />
          <Route path={["/experience"]} component={Experience} />
          <Route exact path={["/projects"]} component={ProjectList} />
          <Route
            path={["/projects/opinionsurveys"]}
            component={OpinionSurveys}
          />
          <Route path={["/projects/payroll"]} component={Payroll} />
          <Route path={["/projects/disability"]} component={Impairment} />
          <Route
            path={["/projects/adaptivequestionnaire"]}
            component={AdaptiveQuestionnaire}
          />
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default App;

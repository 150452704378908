import React from "react";

const ContactForm = () => {
  return (
    <form
      name="contact"
      method="post"
      className="flex flex-col"
      data-netlify="true"
      netlify-honeypot="extra"
      data-netlify-recaptcha="true"
    >
      <label className="h-0 overflow-hidden">
        <input type="hidden" name="form-name" value="contact" />
        <input name="title" />
      </label>
      <label>
        <span className="block font-medium text-gray-600">Your Name</span>
        <input
          id="name"
          name="name"
          type="text"
          className="w-full px-2 py-1 text-lg"
        />
      </label>
      <label>
        <span className="block mt-3 font-medium text-gray-600">
          Email Address
        </span>
        <input
          id="email"
          name="email"
          type="text"
          className="w-full px-2 py-1 text-lg"
        />
      </label>
      <label>
        <span className="block mt-3 font-medium text-gray-600">
          Phone Number
        </span>
        <input
          id="phone"
          name="phone"
          type="text"
          className="w-full px-2 py-1 text-lg"
        />
      </label>
      <label>
        <span className="block mt-3 font-medium text-gray-600">Details</span>
        <textarea
          id="details"
          name="details"
          rows="4"
          className="w-full px-2 py-1 text-lg"
        />
      </label>
      <div className="mt-4" data-netlify-recaptcha="true"></div>
      <div>
        <button
          type="submit"
          className="w-full md:w-auto mt-3 px-8 py-2 font-semibold border-2 rounded-lg border-gray-600 focus:outline-none focus:border-green-500 hover:bg-white"
        >
          Send
        </button>
      </div>
    </form>
  );
};

export default ContactForm;

import React from "react";
import ReactGA from "react-ga";
import ProjectItem from "./ProjectItem";

const ProjectList = () => {
  ReactGA.event({ category: "Navigate", action: "Projects" });

  return (
    <section className="mt-8 sm:mt-12 md:mb-16 text-lg">
      <h1 className="text-xl xs:text-2xl sm:text-3xl font-semibold text-center">
        Notable Projects
      </h1>
      <p className="mt-2 mx-4 text-base max-w-3xl text-center xs:text-lg md:mx-auto md:mt-8">
        During my career I have worked on innumerable projects for dozens of
        clients. However there are a few that stand out in my mind as
        particularly interesting or impactful.
      </p>

      <ProjectItem
        title="Adaptive Questionnaire"
        url="/projects/adaptivequestionnaire"
        imageUrl={require("../../../images/question-mark-1495858_1280.jpg")}
        imageAlt="Red question mark on a pile of gray question marks"
        rightAlign={true}
        text="A system used to identify new business opportunities 
        by capturing and exploring new knowledge gained after each service engagement"
      />
      <ProjectItem
        title="Piece Rate Payroll"
        url="/projects/payroll"
        imageUrl={require("../../../images/timesheet-crop.jpg")}
        imageAlt="Optical Mark Reader Timesheet"
        rightAlign={false}
        text="A commercial Payroll system using optical mark recognition to
          significantly reduce the burden of tracking activities and
          producing payroll at Sheltered Workshops"
      />

      <ProjectItem
        title="Employee Opinion Surveys"
        url="/projects/opinionsurveys"
        imageUrl={require("../../../images/survey-crop.jpg")}
        imageAlt="Survey Form"
        rightAlign={true}
        text="Systems supporting management consultants in creating, inputting
          and analyzing the results of Employee Opinion Surveys"
      />

      <ProjectItem
        title="Disability Evaluations"
        url="/projects/disability"
        imageUrl={require("../../../images/ama-guides-crop.jpg")}
        imageAlt="Book Cover"
        rightAlign={false}
        text="A commercial product to assist medical evaluators in determining
          the level of a person's permanent disability for Worker's
          Compensation claims"
      />
    </section>
  );
};

export default ProjectList;

import React, { useEffect, useRef } from "react";

const WordFader = ({
  fadeDelay,
  initialDelay,
  wordDelay,
  children,
  words: wordList
}) => {
  const words = JSON.parse(wordList);
  const elementRef = useRef(null);

  let currentWord = 0;
  let isVisible = true;
  let isStrike = false;

  const fade = () => {
    if (elementRef.current === null) {
      return;
    }

    let delay = fadeDelay;

    if (isVisible && isStrike) {
      elementRef.current.classList.add("opacity-0");
      isVisible = false;
      isStrike = false;
    } else if (isVisible && !isStrike) {
      elementRef.current.classList.add("strikethrough");
      isStrike = true;
    } else {
      delay = wordDelay;
      currentWord++;
      elementRef.current.innerText = words[currentWord];
      elementRef.current.classList.remove("strikethrough", "opacity-0");
      isVisible = true;
      isStrike = false;
    }

    if (currentWord < words.length - 1) {
      setTimeout(() => {
        fade();
      }, delay);
    } else {
      elementRef.current.classList.add("font-semibold", "text-blue-900");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      fade();
    }, initialDelay);
    // Disabling the linter for this is frowned upon, but using the "correct"
    // react workarounds leads to a matryoshka doll of useCallback and useRef
    // wrapped functions and variables, so screw it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span
      className="word-fader relative transition-opacity transition-ease-out transition-delay-250"
      ref={elementRef}
    >
      {words.length > 0 ? words[0] : children}
    </span>
  );
};

export default WordFader;

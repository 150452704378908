import React from "react";
import ReactGA from "react-ga";
import WordFader from "./WordFader";
import ContactForm from "./ContactForm";
import HeroBackground from "../../../images/network-2402637_1280-small.jpg";

const Home = () => {
  ReactGA.event({ category: "Navigate", action: "About" });

  return (
    <React.Fragment>
      <section
        className="relative bg-cover bg-center"
        style={{ backgroundImage: `url(${HeroBackground})` }}
      >
        <div
          className="relative h-full w-full lg:px-auto py-20 sm:py-32 lg:py-40 font-medium text-center"
          style={{
            backgroundColor: "rgba(235,248,255,0.85)",
          }}
        >
          <h1 className="block sm:inline-block text-2xl md:text-4xl">
            Hello, I'm Stephen.
          </h1>
          <p className="block sm:inline-block sm:pl-2 text-2xl md:text-4xl">
            I{" "}
            <WordFader
              words='["write programs.", "develop software.", "build applications.", "create solutions."]'
              fadeDelay="800"
              initialDelay="1500"
              wordDelay="2500"
            />
          </p>

          <p className="mx-6 mt-8 text-lg sm:mx-12 sm:mt-12 md:text-xl lg:mt-16 lg:mx-auto lg:max-w-3xl lg:text-2xl">
            As a fully remote full-stack developer, I'm always looking for
            interesting projects to work on and problems to solve.
          </p>
          <a
            href="#contact"
            role="button"
            className="inline-block mt-8 sm:mt-12 lg:mt-16 px-4 py-2 text-xl text-blue-100 bg-blue-800 font-semibold border-1 border-white rounded-lg focus:bg-blue-100 focus:outline-none focus:text-blue-800 hover:bg-blue-100 hover:text-blue-800"
          >
            Contact me
          </a>
        </div>
      </section>
      <section className="md:py-12 lg:py-20 lg:max-w-5xl lg:mx-auto">
        <div className="flex flex-col md:flex-row">
          <article className="w-full px-8 py-8 text-lg text-left sm:px-12 md:py-0 md:w-1/2">
            <h2 className="text-xl font-semibold lg:text-2xl text-center">
              Background
            </h2>
            <p className="mt-4">
              I have more than 30 years of professional software development
              experience, ranging from vertical market commercial products to
              custom solution development for a wide array of clients.
            </p>
            <p className="mt-4">
              Most of my career has been focused on creating commercial and
              enterprise solutions, and I have most recently worked as a
              Developer Consultant serving clients in the public and private
              sectors.
            </p>
            <p className="mt-4">
              I am platform agnostic and have used my deep love of learning to
              transition between languages, tools, and frameworks to accommodate
              technological evolution and the demands of the project.
            </p>
          </article>
          <aside className="w-full px-4 py-8 text-lg bg-blue-100 sm:px-8 md:py-0 md:pr-12 md:bg-gray-100 md:mt-0 md:w-1/2">
            <h2 className="text-xl font-semibold lg:text-2xl text-center">
              Current Skills
            </h2>
            <div className="mt-4 flex justify-around">
              <div>
                <p className="font-medium">Microsoft .NET Platform</p>
                <ul className="pl-4">
                  <li>C#, Linq, Entity Framework</li>
                  <li>ASP.Net MVC and Web API</li>
                  <li>SharePoint Server</li>
                </ul>
                <p className="mt-4 font-medium">Web Development</p>
                <ul className="pl-4">
                  <li>HTML5, CSS3, JavaScript</li>
                  <li>jQuery, React, Next.js</li>
                  <li>TailwindCSS, BootStrap</li>
                  <li>PHP, Laravel, Drupal</li>
                </ul>
                <p className="mt-4 font-medium">Database</p>
                <ul className="pl-4">
                  <li>SQL Server, T-SQL</li>
                  <li>MariaDB (MySQL)</li>
                </ul>
              </div>
            </div>
          </aside>
        </div>
      </section>
      <section
        id="contact"
        className="w-full py-8 md:py-16 lg:py-24 bg-gray-300"
      >
        <div className="flex flex-col md:flex-row justify-center items-center md:items-baseline max-w-5xl mx-auto">
          <div className="w-4/5 md:w-1/3">
            <h2 className="text-xl font-semibold lg:text-2xl text-center md:text-left">
              Contact Me
            </h2>
            <div className="flex flex-col xs:flex-row md:block items-center xs:justify-around">
              <div>
                <a
                  href="mailto:contact@stephen-cochran.dev"
                  title="Email me"
                  className="flex items-center mt-4"
                >
                  <svg
                    className="inline-block h-6 w-6 fill-current text-blue-600 hover:text-blue-400 focus:text-blue-400 focus:outline-none"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24.030941 7.93354195v11.2850226a2.4061881 2.4061881 0 01-2.406188 2.406188H2.3752475a2.4061881 2.4061881 0 01-2.40618802-2.406188V7.93354195l11.48954952 5.7387596a1.2030941 1.2030941 0 001.082784 0z" />
                    <path d="M12.000001 11.25408155L-.03094052 5.23861125v-.4571757c0-1.3234035 1.08278452-2.4061881 2.40618802-2.4061881H21.624753a2.4061881 2.4061881 0 012.406188 2.4061881v.4571757z" />
                  </svg>
                  <span className="ml-4">Send an Email</span>
                </a>
              </div>
              <div>
                <a
                  href="https://linkedin.com/in/srcochran"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center mt-4"
                  title="View my LinkedIn profile"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.0"
                    viewBox="0 0 24 24"
                    className="h-6 w-6 inline-block text-blue-600 fill-current hover:text-blue-400 focus:text-blue-400 focus:outline-none"
                  >
                    <path d="M24.039604 22.497525c0 .829366-.673109 1.502475-1.502475 1.502475H1.5024752C.67310891 24 0 23.326891 0 22.497525V1.4628713C0 .63350495.67310891-.03960396 1.5024752-.03960396H22.537129c.829366 0 1.502475.67310891 1.502475 1.50247526z" />
                    <g
                      transform="matrix(.75124 0 0 .75124 0 -.03960396)"
                      fill="#FFF"
                    >
                      <path d="M7 11h4v14H7zm13.499 0c-2.791 0-3.271 1.018-3.499 2v-2h-4v14h4v-8c0-1.297.703-2 2-2 1.266 0 2 .688 2 2v8h4v-7c0-4-.521-7-4.501-7z" />
                      <circle cx="9" cy="8" r="2" />
                    </g>
                  </svg>
                  <span className="ml-4">LinkedIn Profile</span>
                </a>
              </div>
            </div>
          </div>
          <div className="w-4/5 md:w-3/5 mt-8 md:mt-0">
            <ContactForm />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Home;

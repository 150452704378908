import React from "react";
import PropTypes from "prop-types";

const ExternalLink = props => {
  return (
    <a
      href={props.to}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-800 cursor-pointer font-semibold hover:text-blue-500 focus:text-blue-500 focus:outline-none hover:outline-none"
    >
      {props.children}
      &nbsp;
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="inline-block align-baseline h-4 w-4 fill-current"
      >
        <path d="M12 8a1 1 0 0 1-1 1H5v10h10v-6a1 1 0 0 1 2 0v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V9c0-1.1.9-2 2-2h6a1 1 0 0 1 1 1z" />
        <path d="M19 6.41L8.7 16.71a1 1 0 1 1-1.4-1.42L17.58 5H14a1 1 0 0 1 0-2h6a1 1 0 0 1 1 1v6a1 1 0 0 1-2 0V6.41z" />
      </svg>
    </a>
  );
};

ExternalLink.propTypes = {
  to: PropTypes.string.isRequired
};

export default ExternalLink;

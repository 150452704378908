import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const InternalLink = props => {
  return (
    <Link
      to={props.to}
      className="text-blue-800 cursor-pointer font-semibold hover:text-blue-500 focus:text-blue-500 focus:outline-none hover:outline-none"
    >
      {props.children}
    </Link>
  );
};

InternalLink.propTypes = {
  to: PropTypes.string.isRequired
};

export default InternalLink;

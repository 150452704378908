import React from "react";

const Footer = () => {
  return (
    <footer className="mt-auto -mb-1 bg-blue-900 text-blue-100">
      <div className="max-w-5xl flex flex-col items-center sm:flex-row sm:justify-around mx-4 sm:mx-auto py-6">
        <div>
          <a
            href="mailto:contact@stephen-cochran.dev"
            className="font-medium hover:text-blue-400 focus:text-blue-400 focus:outline-none"
          >
            contact@stephen-cochran.dev
          </a>
        </div>
        <div className="mt-2 sm:mt-0">
          <a
            href="https://linkedin.com/in/srcochran"
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium hover:text-blue-400 focus:text-blue-400 focus:outline-none"
          >
            linkedin/in/srcochran
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import ReactGA from "react-ga";
import ExternalLink from "../../Utilities/ExternalLink";

const Payroll = () => {
  ReactGA.event({ category: "Navigate", action: "Payroll" });

  return (
    <article className="flex flex-col xs:flex-row min-h-screen text-gray-900">
      <aside className="bg-gray-400 xs:w-1/2 md:w-1/3 lg:w-3/10 p-2 sm:p-4 lg:p-8">
        <div className="px-4 pb-4 " style={{ top: "80px" }}>
          <h1 className="pt-8 text-xl font-bold text-blue-900">
            Piece Rate Payroll
          </h1>
          <p className="mt-8 text-sm font-bold whitespace-no-wrap">Clients:</p>
          <p className="font-semibold text-blue-900">Sheltered Workshops,</p>
          <p className="font-semibold text-blue-900">
            Manufacturing and Industrial Companies
          </p>
          <p className="mt-8 text-sm font-bold whitespace-no-wrap">Overview:</p>
          <p className="font-medium leading-relaxed">
            Companies who pay by the number of items processed or produced face
            unique challenges. Sheltered Workshops, in particular, were heavily
            burdened by additional time recording and activity tracking
            requirements. I developed a Payroll system that met the challenge
            and significantly reduced the overhead for payroll tracking, data
            entry, and record-keeping, while maintaining full Department of
            Labor compliance for both piece rate and sub-minimum wage
            operations.
          </p>
          <p className="mt-6 font-medium leading-relaxed">
            As a result, piece-rate employers, and Sheltered Workshops in
            particular, saw up to a 90% reduction in staff time required to
            track activities and produce payroll.
          </p>
        </div>
      </aside>
      <section className="px-4 sm:px-8 py-8 sm:py-12 lg:py-16 xs:w-1/2 md:w-2/3 lg:w-7/10 font-medium leading-relaxed bg-cover">
        <p className="">
          When I joined JA Erwin &amp; Associates, they were selling an
          unsupported accounting system that had been modified to handle Piece
          Rate payroll but wasn't well suited to the needs of our clients. I was
          tasked with a ground-up re-write tailored to the special needs of
          Sheltered Workshops, while maintaining its applicability to the
          broader payroll market.
        </p>

        <p className="mt-6">
          Sheltered Workshops operate under a special provision of Department of
          Labor regulations that allow them to pay sub-minimum-wage rates in
          exchange for offering jobs, life-skills training, and other support
          services to clients with physical or mental disabilities. In order to
          prevent abuse of their minimum wage waiver, they must track every
          activity of their clients from the moment they arrive until the minute
          they leave. This includes both paid and unpaid activities, such as
          tasks performed, training time, scheduled and un-scheduled breaks, and
          other down time as needed. On average, every client at a workshop
          would switch activities more than 12 times a day. The workshop was
          required to track the start time, end time, specific activity, and any
          work accomplished during that activity.
        </p>

        <p className="mt-6">
          For a workshop to support 60 clients, they needed 4 full-time staff
          members to document these activities and produce payroll. As the
          workshops were non-profit organizations and heavily funded by grants
          and charitable contributions, resources were scarce, and every dollar
          spent on clerical overhead shifted money away from client services.
          Often, client support staff was performing much of the record-keeping,
          taking their attention away from the clients.
        </p>

        <p className="mt-6">
          After exploring a number of point-of-work data collection
          technologies, we found them to be cost-prohibitive for our customers
          and opted for the time-tested alternative: Pencil and paper.
        </p>

        <p className="mt-6">
          Designing a set of Optical Mark Recognition forms that could
          individually track up to 12 daily activities per full sheet (
          <ExternalLink to="https://store.scantron.com/OA_HTML/ibeCCtpItmDspRte.jsp?item=41551">
            sample half-sheet
          </ExternalLink>
          ), we reduced the time to record a task-switch event to seconds while
          eliminating the need to gather data from a variety of log-books and
          hand-enter the clients' activities. In the end, workshops were able to
          drastically reduce the time and staff required to produce payroll, and
          also maintain a robust paper trail they could refer to if they needed
          to document their pay practices.
        </p>

        <p className="mt-6">
          Ultimately, the effort required to run a 60-client workshop was
          reduced from 4 full-time employees to 1 person, 3 days per pay period.
          This allowed them to return staff to their client-support roles and
          focus their resources where they belonged.
        </p>

        <p className="mt-6">
          Our software was adopted by almost 100 Sheltered Workshops across the
          country, as well as a number of industrial and manufacturing companies
          who operated on a piece rate/guaranteed minimum wage basis.
        </p>
        <p className="mt-6">
          The IP to our payroll system was purchased by Topeka Business
          Computers in 1992, and they still sell a descendant of the system
          today (TBC Activity Tracker).
        </p>
      </section>
    </article>
  );
};

export default Payroll;

import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ProjectItem = props => {
  const { rightAlign, url, imageUrl, imageAlt, title, text } = props;

  return (
    <Link
      to={url}
      className={`mt-4 mx-0 py-8 px-2 flex flex-col focus:outline-none focus:text-blue-700 hover:text-blue-700 ${
        rightAlign
          ? "md:flex-row-reverse"
          : "md:flex-row bg-blue-100 md:bg-gray-100"
      } items-center max-w-3xl sm:mt-10 md:mt-16 md:mx-auto md:px-4 md:flex-row lg:mt-24`}
    >
      <div className="h-32 w-32 xs:h-48 xs:w-48 flex-shrink-0 rounded-full hover:shadow-xl overflow-hidden">
        <img
          src={imageUrl}
          alt={imageAlt}
          className="object-cover scale-100 transition-transform transition-ease-in-out hover:scale-130"
        />
      </div>
      <div
        className={`mt-4 text-center ${
          rightAlign
            ? "mr-0 md:mr-4 md:text-right"
            : "ml-0 md:ml-4 md:text-left"
        } md:mt-0 md:ml-8`}
      >
        <p className="text-lg xs:text-xl sm:text-2xl font-semibold">{title}</p>
        <p className="mt-2 xs:text-lg">{text}</p>
      </div>
    </Link>
  );
};

ProjectItem.propTypes = {
  rightAlign: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default ProjectItem;
